<template>
    <div class="premium-survey-question">
        <StackRouterHeaderBar :leftButtonHandler="prev" />
        <div class="container">
            <PremiumSurveyStep :step="question.step" />
            <div class="title" v-html="(question.question || '').replace('%s', name)" />
            <div class="desc" v-if="!isLastQuestion" v-html="question.description" />
            <div v-if="question.question_type === 'select' || question.question_type === 'both'" class="select-answers">
                <div
                    v-for="answer in answers"
                    class="answer"
                    :class="{ selected: answer.selected }"
                    :key="answer.id"
                    v-html="answer.answer"
                    @click="select(answer.id)"
                />
            </div>
            <div v-if="question.question_type === 'range'">
                <div v-for="answer in answers" class="range-answer" :key="answer.id">
                    <span class="range-answer" v-html="answer.answer" />
                    <PremiumSurveyRangeAnswer
                        @submit="rangeSelect(answer.id, $event)"
                        :initValue="((myAnswer[`${question.id}`] || {}).answer || {})[answer.id]"
                    />
                </div>
            </div>
            <div v-if="question.question_type === 'input'">
                <input
                    class="input-answer"
                    :placeholder="question.placeholder || '입력해주세요'"
                    v-model="textAnswer"
                />
            </div>
            <div
                v-if="question.question_type === 'text' || (question.question_type === 'both' && isEtc)"
                class="text-answer textarea-with-x textarea-wrapper flex-row"
            >
                <textarea
                    ref="textarea"
                    v-model="textAnswer"
                    class="flex-fill"
                    :placeholder="question.placeholder || '내용을 입력해 주세요'"
                />
                <i v-if="(textAnswer || '').length > 0" @click="textAnswer = ''" class="material-icons flex-wrap"
                    >cancel</i
                >
            </div>
            <div v-if="isLastQuestion" class="photo-wrapper center m-t-40">
                <img :src="require('../../assets/images/premium/premium_survey_tag.png')" />
            </div>
        </div>
        <div v-if="showButton" class="button-wrapper w-100">
            <button
                class="btn btn-primary f-15 w-100"
                :disabled="!answerFulfilled"
                @click="next"
                v-html="buttonLabel"
            />
        </div>
    </div>
</template>
<script>
import premiumSurveyQuestionService from '@/services/premium-survey'
import PremiumSurveyRangeAnswer from '@/routes/premium-survey/PremiumSurveyRangeAnswer'
import PremiumSurveyStep from '@/routes/premium-survey/PremiumSurveyStep'
export default {
    name: 'PremiumSurveyQuestionPage',
    components: { PremiumSurveyStep, PremiumSurveyRangeAnswer },
    data: () => ({
        questions: [],
        index: 0,
        textAnswer: '',
        myAnswer: {},
    }),
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
        this.init()
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#0E0B54',
        })
    },
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        name() {
            return this.$store.getters.me.name || this.$store.getters.me.profile.nickname
        },
        answerFulfilled() {
            if (this.question.is_skippable) return true

            switch (this.question.question_type) {
                case 'text':
                case 'input':
                    return !!this.textAnswer
                case 'select':
                    return this.answers.some(e => e.selected)
                case 'both': {
                    if (this.isEtc) {
                        return !!this.textAnswer
                    } else {
                        return this.answers.some(e => e.selected && e.answer !== '직접 입력')
                    }
                }
                case 'range':
                    return this.answers.every(e => !!e.selected)
                default:
                    return true
            }
        },
        isLastQuestion() {
            return this.questions.length - 1 === this.index // 일단 하드코딩
        },
        buttonLabel() {
            return this.isLastQuestion ? this.$translate('DO_SUBMIT') : this.$translate('NEXT')
        },
        isEtc() {
            if (this.question.question_type === 'both') {
                const selected = this.answers.filter(e => e.selected && e.answer === '직접 입력')
                return selected.length > 0
            }
            return false
        },
        showButton() {
            switch (this.question.question_type) {
                case 'select':
                    return this.question.is_multiple_choice
                case 'both':
                    return this.question.is_multiple_choice || this.isEtc
                case 'text':
                case 'input':
                case 'range':
                case 'none':
                    return true
                default:
                    return false
            }
        },
        question() {
            return this.questions[this.index] || {}
        },
        answers() {
            return (this.questions[this.index] || {}).premium_survey_answer || []
        },
    },
    methods: {
        async init() {
            const data = await premiumSurveyQuestionService.all()
            this.questions = data
            this.questions.forEach(q => {
                ;(q.premium_survey_answer || []).forEach(e => {
                    this.$set(e, 'selected', false)
                })
            })
        },
        select(answerId) {
            if (
                (this.question.question_type === 'select' || this.question.question_type === 'both') &&
                !this.question.is_multiple_choice
            ) {
                this.answers.forEach(e => {
                    e.selected = e.id === answerId
                })
            } else {
                const answer = this.answers.find(e => e.id === answerId)
                answer.selected = !answer.selected
            }

            if (
                (this.question.question_type === 'select' || (this.question.question_type === 'both' && !this.isEtc)) &&
                !this.question.is_multiple_choice
            ) {
                setTimeout(() => {
                    this.next()
                }, 500)
            }
        },
        rangeSelect(answerId, event) {
            this.answers.find(e => e.id === answerId).selected = event
        },
        prev() {
            if (this.index === 0) this.$stackRouter.pop()
            else {
                this.index -= 1
                if (this.isEtc || this.question.question_type === 'input' || this.question.question_type === 'text') {
                    this.textAnswer = this.myAnswer[`${this.question.id}`].answer
                } else {
                    this.textAnswer = ''
                }
            }
        },
        next() {
            if (this.isLastQuestion) this.doSubmit()
            else {
                let answerValue

                switch (this.question.question_type) {
                    case 'text':
                    case 'input':
                        answerValue = this.textAnswer
                        break
                    case 'select':
                        answerValue = this.answers.filter(e => e.selected).map(e => e.answer)
                        break
                    case 'range':
                        answerValue = {}
                        this.answers.forEach(e => {
                            answerValue[e.id] = { question: e.answer, answer: e.selected }
                        })
                        break
                    case 'both':
                        answerValue = this.answers
                            .filter(e => e.selected && e.answer !== '직접 입력')
                            .map(e => e.answer)
                        if (this.isEtc) {
                            answerValue.push(this.textAnswer)
                        }
                        break
                }
                if (this.question.step === 3 && this.question.is_multiple_choice && answerValue.length > 5) {
                    this.$toast.error('최대 5개만 선택 가능해요')
                    return
                }

                this.myAnswer[`${this.question.id}`] = { question: this.question.question, answer: answerValue }
                this.textAnswer = ''
                this.index += 1
            }
        },
        async doSubmit() {
            try {
                const surveyResult = JSON.stringify(this.myAnswer)
                const { msg } = await premiumSurveyQuestionService.post({
                    result: surveyResult,
                    chat_id: this.chat.id,
                })
                this.$toast.success(msg)
                this.$stackRouter.clear()
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.container {
    line-height: normal;
    letter-spacing: -0.2px;
    padding: 16px;

    @media (max-height: 820px) {
        padding-bottom: 81px;
    }
}
.title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: black;
    @include f-medium;

    ::v-deep span {
        color: $brand-premium-blue;
        @include f-bold;
    }
}

.desc {
    margin-top: 16px;
    margin-bottom: 40px;
    font-size: 14px;
    color: #262289;
    @include f-regular;

    ::v-deep span {
        @include f-bold;
    }
}

.answer {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid $grey-03;
    font-size: 16px;
    color: black;

    &:not(last-child) {
        margin-bottom: 10px;
    }

    &.selected {
        border: 1px solid #262289;
        color: #262289;
        @include f-medium;
    }
}

.input-answer {
    width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey-03;
}

.text-answer {
    height: 240px;
    margin-bottom: 16px;

    &:focus-within {
        border: 1px solid #262289;
    }
}

.range-answer {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    color: $grey-08;
    @include f-medium;

    &:not(last-child) {
        margin-bottom: 57px;
    }
}

.button-wrapper {
    padding: 16px 20px;
    border-top: 1px solid $grey-02;
    position: absolute;
    bottom: 0;
    background-color: white;

    .btn {
        height: 48px;
        color: $white;
        background-color: #262289;
        @include f-medium;

        &:disabled {
            background-color: $grey-02;
            color: $grey-05;
        }
    }
}

img {
    height: 350px;
}
</style>
