<template>
    <div class="photo-wrapper m-b-16">
        <img v-if="step" :src="require(`../../assets/images/premium/step_${step}.png`)" />
    </div>
</template>
<script>
export default {
    name: 'PremiumSurveyStep',
    props: ['step'],
}
</script>
<style lang="scss" scoped>
img {
    width: 82px;
}
</style>
