<template>
    <div class="premium-survey-range-answer m-t-12">
        <div class="button-wrapper flex-row m-b-8">
            <button
                v-for="button in buttons"
                :key="button.id"
                :class="{ selected: button.selected }"
                v-html="button.id"
                @click="onClick(button.id)"
            />
        </div>
        <div class="flex-row flex-between">
            <span class="range-desc">중요하지 않음</span>
            <span class="range-desc">매우 중요</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PremiumSurveyRangeAnswer',
    props: ['initValue'],
    data: () => ({
        buttons: [],
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const selectedAnswer = (this.initValue || {}).answer || 0
            for (let i = 1; i < 6; i++) {
                this.buttons.push({
                    id: i,
                    selected: selectedAnswer === i,
                })
            }
        },
        onClick(id) {
            this.buttons.forEach(b => {
                b.selected = b.id === id
            })
            this.$emit('submit', id)
        },
    },
}
</script>
<style lang="scss" scoped>
button {
    height: 36px;
    border: 1px solid white;
    background-color: $grey-01;
    border-radius: 2px;
    font-size: 14px;
    color: $grey-07;
    @include f-regular;

    &.selected {
        background-color: $purple-intensive;
        color: white;
        @include f-medium;
    }

    &:first-child {
        border-radius: 8px 2px 2px 8px;
    }

    &:last-child {
        border-radius: 2px 8px 8px 2px;
    }
}

.range-desc {
    font-size: 12px;
    color: $grey-06;
    @include f-regular;
}
</style>
